<template>
    <div id="card-pending-order-component">
        <v-card elevation="0" :to="userHasAccess('ORDC',role_edit) ? ('/orders_cockpit/' + order.id_order) : '#'">
            <v-card-title>
                <chips-last-statement
                    :danger="345600"
                    :date="order.date_creation"
                    :warning="172800"
                    :previous_text="$t('chips_last_statement.send')"
                />
            </v-card-title>

            <v-card-text class="text-center">
                <div v-if="show('order_number')">
                    <span v-html="$t('card_pending_order_component.order_is_pending_confirmation', {order_number: order.order_number})"/>
                </div>
                <display-liquid-level-component
                    :actual_volume="order.asset.actual_volume"
                    :id_asset="order.asset.id_asset"
                    :show_indicator="false"
                    :volume_total="order.asset.volume"
                    :type_asset="order.asset.type_asset"
                    class="my-2"
                />

                <div class="my-1" v-if="show('asset.name_asset')">
                    <span class="font-weight-bold">{{ $t('card_pending_order_component.asset') }}</span><br>
                    <span>{{ order.asset.name_asset }}</span>
                </div>

                <div class="my-1" v-if="show('address')">
                    <span class="font-weight-bold">{{ $t('card_pending_order_component.address_postal') }}</span><br>
                    <span>{{ order.asset.address }}</span>
                </div>

                <div class="my-1" v-if="show('quantity')">
                    <span class="font-weight-bold">{{ $t('card_pending_order_component.quantity') }}</span><br>
                    <span>{{ order.quantity }} m³</span>
                </div>


                <div class="my-1" v-if="show('product_price.cotation_method') && order.product_price">
                    <span class="font-weight-bold">{{ $t('card_pending_order_component.price_liquid') }}</span><br>
                    <span>{{ order.product_price.cotation_method }}</span>
                    <br>
                    <span v-if="order.product_price.price">{{ order.product_price.price }} €/m³</span>
                </div>

                <div class="my-1" v-if="show('desired_date_delivery') && order.desired_date_delivery">
                    <span class="font-weight-bold">{{ $t('card_pending_order_component.desired_delivery_date') }}</span><br>
                    <span>{{ moment(order.desired_date_delivery).format(date_format) }}</span>
                </div>

                <v-btn v-if="userHasAccess('ORDC',role_edit)" block class="mt-2" color="primary"
                       @click="navigateTo('orders_cockpit/' + order.id_order)"
                >
                    <span class="secondary--text font-weight-bold">{{ $t('card_pending_order_component.see_order_proposal') }}</span>
                </v-btn>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import ChipsLastStatement from "@/components/Global/ChipsLastStatement";
import DisplayLiquidLevelComponent from "../Equipment/LiquidLevelComponent/DisplayLiquidLevelComponent";

export default {
    name: 'CardPendingOrderComponent',
    components: {ChipsLastStatement, DisplayLiquidLevelComponent},
    props: {
        order: {
            type: Object,
            require: true
        },
        headers: {
            type: Array,
            require: true
        },
    },
    computed: {
        showPrice() {
            return isNaN(this.order.product_price.price) ? this.order.product_price.cotation_method : this.order.product_price.price + ' €/m³';
        }
    },
    methods: {
        show(text) {
            let show = false;
            this.headers.forEach(header => {
                if (header.value === text) {
                    show = true;
                }
            });
            return show;
        }
    }
}
</script>