<template>
    <div id="list-order-being-validated-component" class="min-height-component-550">
        <p class="font-14 mb-2 font-color-medium">
            {{ $t('list_order_being_validated_component.truck_process_delivery')}}

        </p>

        <p class="font-14 mb-7 font-color-medium">
            {{ $t('list_order_being_validated_component.contact_1')}}
            <a class="font-color-medium font-weight-bold" href="mailto:serviceclient.oleo100@groupeavril.com">
                serviceclient.oleo100@groupeavril.com
            </a>
            {{ $t('list_order_being_validated_component.contact_2')}}
            {{ $t('list_order_being_validated_component.order_length')}}
        </p>

        <datatable-filter-component :headers="headers"/>

        <p class="font-weight-bold">
            {{ $t('list_order_being_validated_component.order_length', {orders : orders.length})}}
        </p>
        <v-data-table
            :headers="headers"
            :items="orders"
            :loading="loading"
            :search="search"
            class="stripe-date-table"
            disable-sort
            item-key="order_number"
        >
            <template v-slot:item.date_order_validation="{ item }">
                <span>{{ moment(item.date_order_validation).format(date_format) }}</span>
            </template>

            <template v-slot:item.desired_date_delivery="{ item }">
                <span>{{ moment(item.desired_date_delivery).format(date_format) }}</span>
            </template>

            <template v-slot:item.quantity="{ item }">
                <span>{{ item.quantity }} m³</span>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import DatatableFilterComponent from "@/components/Global/DatatableFilterComponent";
import OrderCockpitRepository from "@/repositories/OrderCockpitRepository";

export default {
    name: 'ListOrderBeingValidatedComponent',
    components: {DatatableFilterComponent},
    data() {
        return {
            orders: [],
            headers: [
                {text: this.$t('datatable_order_component.order_number'), value: 'order_number', filterable: true},
                {text: this.$t('datatable_order_component.asset_concerned'), value: 'asset.name_asset', filterable: true},
                {text: this.$t('datatable_order_component.city'), value: 'address', filterable: true},
                {text: this.$t('datatable_order_component.delivery_date'), value: 'desired_date_delivery', filterable: true},
                {text: this.$t('datatable_order_component.order_form'), value: 'purchase_order', filterable: true},
                {text: this.$t('datatable_order_component.liquid'), value: 'liquid', filterable: true},
                {text: this.$t('datatable_order_component.quantity'), value: 'quantity', filterable: true},
                {text: this.$t('datatable_order_component.validation_date'), value: 'date_order_validation', filterable: true},
            ],
            loading: false,
            search: ''
        }
    },
    created() {
        this.getOrderCockpitInProgress();
    },
    methods: {
        getOrderCockpitInProgress() {
            this.loading = true;

            OrderCockpitRepository.getOrderCockpitListByState('2,3,4,5').then((success) => {
                this.orders = success.data.data;
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>