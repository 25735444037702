<template>
    <div id="list-order-pending-validation-component" class="min-height-component-550">
        <v-alert v-if="display_no_user_subscribe" close-icon="mdi-close" dense dismissible elevation="1"
                 icon="mdi-alert-octagon-outline" text type="error" class="elevation-0"
        >
            <p class="mb-0 line-height-16 font-14 error--text">
                {{ $t('list_order_pending_validation_component.no_user_subscribe') }}
            </p>
        </v-alert>

        <p class="font-14 mb-2 font-color-medium">
            {{ $t('list_order_pending_validation_component.page_description') }}
        </p>

        <p class="font-14 mb-5 font-color-medium">
            {{ $t('list_order_pending_validation_component.contact_1') }}
            <a class="font-color-medium font-weight-bold" href="mailto:serviceclient.oleo100@groupeavril.com">
                serviceclient.oleo100@groupeavril.com
            </a>
            {{ $t('list_order_pending_validation_component.contact_2') }}
        </p>

        <v-switch v-model="subscribed" class="mb-1" inset @change="orderSubscriptionHandler()">
            <template v-slot:label>
                <span class="font-14 black--text">
                    {{ $t('list_order_pending_validation_component.proposal_order_by_mail') }}
                </span>
            </template>
        </v-switch>

        <datatable-filter-component :headers="headers"/>

        <template v-if="!loading_orders">
            <v-row class="mb-1 mx-0 justify-space-between">
                <p class="font-weight-bold">
                    {{ $t('list_order_pending_validation_component.order_length', {orders : orders.length})}}
                </p>
                <v-btn class="square-btn-icon" color="secondary" outlined>
                    <v-icon color="secondary">mdi-download</v-icon>
                </v-btn>
            </v-row>

            <v-row>
                <v-col v-for="order in orders" :key="order.order_number" cols="12" lg="6">
                    <card-pending-order-component :headers="headers" :order="order" class=""/>
                </v-col>
            </v-row>
        </template>


        <v-row v-else>
            <v-col v-for="n in 2" :key="n" cols="12" md="6">
                <v-skeleton-loader type="card-heading, list-item-two-line, image, table-thead"/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import CardPendingOrderComponent from "@/components/Order/CardPendingOrderComponent";
import DatatableFilterComponent from "@/components/Global/DatatableFilterComponent";
import OrderCockpitRepository from "@/repositories/OrderCockpitRepository";
import ClientSubscriptionRepository from "@/repositories/ClientSubscriptionRepository";

export default {
    name: 'ListOrderWaitingValidationComponent',
    components: {DatatableFilterComponent, CardPendingOrderComponent},
    data() {
        return {
            headers: [
                {text: 'Numéro de commande', value: 'order_number', filterable: true},
                {text: 'Cuve', value: 'asset.name_asset', filterable: true},
                {text: 'Adresse', value: 'asset.address', filterable: true},
                {text: 'Quantité', value: 'quantity', filterable: true},
                {text: 'Méthode de cotation', value: 'product_price.cotation_method', filterable: true},
                {text: 'Prix', value: 'product_price.cotation_method', filterable: true},
                {text: 'Date de livraison souhaitée' , value: 'desired_date_delivery', filterable: true},
            ],
            search: '',
            subscribed: null,
            loading_orders: false,
            display_no_user_subscribe: false
        }
    },
    computed: {
        orders: {
            get: function () {
                return this.$store.getters['orders_cockpit/orders_cockpit_pending_validation'];
            },
            set: function (value) {
                this.$store.dispatch('orders_cockpit/getOrdersCockpitPendingValidation');
            }
        },
        orders_copy: {
            get: function () {
                return this.$store.getters['orders_cockpit/orders_cockpit_pending_validation'];
            },
            set: function (value) {
                this.$store.dispatch('orders_cockpit/getOrdersCockpitPendingValidation');
            }
        }
    },
    created() {
        this.filter();
        this.getSubscriptionDetails();
        this.getTotalSubscriptionByIdClient();
    },
    methods: {
        filter(text) {
            let filter = true;
            this.headers.forEach(header => {
                if (header.value === text) {
                    filter = header.filterable;
                }
            })
            return filter;
        },

        getSubscriptionDetails() {
            ClientSubscriptionRepository.getSubscriptionClient().then((success) => {
                this.subscribed = success.data.data.alert_new_order
            }).catch(() => {
                this.subscribed = false;
            });
        },

        getTotalSubscriptionByIdClient() {
            ClientSubscriptionRepository.getTotalSubscriptionByIdClient(
                this.$store.getters['user_connected/user_connected'].id_client
            ) .then((success) => {
                if(success.data.data === 0) {
                    this.display_no_user_subscribe = true;
                } else {
                    this.display_no_user_subscribe = false;
                }
            }).catch((error) => {
                this.manageError(error);
            });
        },

        orderSubscriptionHandler() {
            if (this.subscribed) {
                this.subscribeUser();
            } else {
                this.unsubscribeUser();
            }
        },

        subscribeUser() {
            ClientSubscriptionRepository.subscribeUserClient().then(() => {
                this.showSnackbar('success', this.$t('list_order_pending_validation_component.subscribe_success'));
                this.subscribed = true;
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.getTotalSubscriptionByIdClient();
            });
        },

        unsubscribeUser() {
            ClientSubscriptionRepository.unsubscribeUserClient().then(() => {
                this.showSnackbar('success', this.$t('list_order_pending_validation_component.unsubscribe_success'));
                this.subscribed = false;
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.getTotalSubscriptionByIdClient();
            });
        }
    },
    watch: {
        search: {
            handler() {
                if (!this.search) {
                    this.orders = this.orders_copy;
                } else {
                    let orders_local = [];
                    this.orders_copy.forEach(order => {
                        let include = false;

                        if (this.filter('order_number')) {
                            if (order.order_number.includes(this.search)) {
                                orders_local.push(order);
                                include = true;
                            }
                        }

                        if (this.filter('asset.name_asset')) {
                            if (order.asset.name_asset.includes(this.search)) {
                                orders_local.push(order);
                                include = true;
                            }
                        }

                        if (this.filter('address') && !include) {
                            if (order.address.includes(this.search)) {
                                orders_local.push(order);
                                include = true;
                            }
                        }

                        if (this.filter('quantity') && !include) {
                            if (order.quantity.toString().includes(this.search)) {
                                orders_local.push(order);
                                include = true;
                            }
                        }

                        if (this.filter('product_price.cotation_method') && !include) {
                            if (order.product_price.cotation_method.includes(this.search)) {
                                orders_local.push(order);
                            }
                        }

                        if (this.filter('product_price.price') && !include) {
                            if (order.product_price.price.toString().includes(this.search)) {
                                orders_local.push(order);
                            }
                        }
                    });

                    this.orders = orders_local;
                }
            },
            deep: true
        }
    }
}
</script>