<template v-if="userHasAccess('ORDC',role_vision)">
    <div id="order-page">
        <toolbar-component title="Commandes"/>

        <v-tabs v-model="tab" background-color="secondary" color="primary" show-arrows class="default-arrows-tabs">
            <v-tabs-slider color="primary"/>

            <v-tab v-for="item in items" :key="item.key" class="primary--text">
                <span
                    :class="tab === item.key ? 'primary--text font-weight-bold' : 'tab-opacity primary--text font-weight-bold'"
                >
                    {{ item.value }}
                </span>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item class="background pa-4" v-for="item in items" :key="item.key">
                <div v-if="item.key === 0">
                    <list-order-pending-validation-component/>
                </div>
                <div v-else-if="item.key === 1">
                    <list-order-being-validated-component/>
                </div>
                <div v-else-if="item.key === 2">
                    <list-order-validated-component/>
                </div>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import ToolbarComponent from "@/components/Global/ToolbarComponent";
import ListOrderValidatedComponent from "@/components/Order/ListOrderValidatedComponent";
import ListOrderBeingValidatedComponent from "@/components/Order/ListOrderBeingValidatedComponent";
import ListOrderPendingValidationComponent from "@/components/Order/ListOrderPendingValidationComponent";

export default {
    name: "OrderCockpitPage",
    components: {
        ListOrderPendingValidationComponent,
        ListOrderBeingValidatedComponent,
        ListOrderValidatedComponent,
        ToolbarComponent
    },
    created() {
        if (this.$route.query.tab && this.$route.query.tab <= 2) {
            this.tab = parseInt(this.$route.query.tab);
        }
    },
    data() {
        return {
            items: [
                {
                    key: 0,
                    value: this.$t('order_cockpit_page.pending_validation')
                },
                {
                    key: 1,
                    value: this.$t('order_cockpit_page.in_progress')
                },
                {
                    key: 2,
                    value: this.$t('order_cockpit_page.completed')
                }
            ],
            tab: null,
            subscribed: false,
        }
    }
}
</script>